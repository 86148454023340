import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import resource from '../../assets/images/resources/about-5-1.png'
import resource01 from '../../assets/images/resources/about-5-v-1.png'

function Company() {

    const [ytShow, setytShow] = useState(false);
   
    return (
        <>
            <section className="about-five pt-120 pb-120">
                <div className="about-five__shape-1"></div>
                <div className="about-five__shape-2"></div>
                <div className="about-five__shape-3"></div>
                <div className="container">
                    <div className="row row-gutter-y-60">
                        <div className="col-lg-6">
                            <div className="about-five__image wow fadeInLeft" data-wow-duration="1500ms">
                                <img src={resource} alt="" />
                                <div className="about-five__image__caption">
                                    <div className="about-five__image__caption__shape-1"></div>
                                    <div className="about-five__image__caption__shape-2"></div>
                                    <div className="about-five__image__caption__shape-3"></div>
                                    <h3 className="about-five__image__title">
                                        25<i>+</i>
                                    </h3>
                                    <p className="about-five__image__text">Years Experiecne</p>
                                </div>
                                <div className="about-five__image__floated">Finlon</div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-five__content">
                                <div className="block-title text-left">
                                    <p className="block-title__tagline">About Company</p>
                                    <h2 className="block-title__title">Five Years, Millions of Milestones.</h2>
                                </div>
                                <h3 className="about-five__subtitle">More Than Just a Loan,We are Partners.</h3>
                                <p className="about-five__text">{process.env.REACT_APP_COMPANY_NAME} has been proudly serving our community's lending needs since 2018. Founded by <a href="https://www.crunchbase.com/person/hayes-barnard">Hayes Barnard</a>, our company is committed to helping our customers achieve their financial goals through responsible lending. Over the past 5 years, we have assisted hundreds of individuals and businesses in obtaining loans to purchase homes, vehicles, cover wedding expenses, invest in property, and expand their companies.</p>
                                <div className="team-progress__item">
                                    <h4 className="team-progress__title">Loan success</h4>
                                    <div className="team-progress__bar">
                                        <div className="team-progress__bar__inner count-bar" data-percent="100%" style={{ width: "85%" }}  >
                                            <div className="team-progress__bar__text count-text">89%</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-progress__item">
                                    <h4 className="team-progress__title">Customers Experience</h4>
                                    <div className="team-progress__bar">
                                        <div className="team-progress__bar__inner count-bar" data-percent="100%" style={{ width: '98%' }}>
                                            <div className="team-progress__bar__text count-text">98%
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="row row-gutter-y-30">
                                    <div className="col-md-6">
                                        <ul className="list-unstyled ml-0 about-two__list">
                                            <li>
                                                <i className="fa fa-arrow-circle-right"></i>
                                                Business loans.
                                            </li>
                                            <li>
                                                <i className="fa fa-arrow-circle-right"></i>
                                                property Loans.
                                            </li>
                                            <li>
                                                <i className="fa fa-arrow-circle-right"></i>
                                                Auto Loans.
                                            </li>
                                            <li>
                                                <i className="fa fa-arrow-circle-right"></i>
                                                Wedding Loans.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="about-five__video">
                                            <img src={resource01} alt="" />
                                            <Link to="#" onClick={() => setytShow(true)} className="video-popup about-five__video__btn">
                                                <i className="fa fa-play"></i>
                                                <span className="ripple"></span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {ytShow &&
                <>
                    <div className="mfp-bg mfp-fade mfp-ready" style={{ height: '7368px', position: 'absolute' }}></div>
                    <div className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-fade mfp-ready" tabindex="-1" style={{ top: '1561px', position: 'absolute', height: '310px' }}>
                        <div className="mfp-container mfp-s-ready mfp-iframe-holder">
                            <div className="mfp-content">
                                <div className="mfp-iframe-scaler">
                                    <button title="Close (Esc)" type="button" className="mfp-close" onClick={() => setytShow(false)}>×</button>
                                    <iframe className="mfp-iframe" src="//www.youtube.com/embed/m2b9ZTBlW2k?autoplay=1" frameborder="0" allowFullScreen=""></iframe>
                                </div>
                            </div>
                            <div className="mfp-preloader">Loading...</div>
                        </div>
                    </div>
                </>}
        </>
    )
}

export default Company