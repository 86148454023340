import React from "react";
import Bredcom from '../../Bredcom/Main'
import First from "../../Menloaneg/First";

const LoanEligibility = () => {

  return (
    <>
      <Bredcom title={"Home"} subtitle={"LOAN ELIGIBILITY"} link={"Loan Eligibility"} />
      <First />
    </>
  );
};

export default LoanEligibility;
