import React from 'react'
import Bredcom from '../../Bredcom/Main'
import First from '../Mennewsdeatils/First'

const NewsDetails = () => {
    return (
        <>
            <Bredcom title={"Home"} subtitle={"Blog"} link={"Blog Details"}/>
            <First/>
        </>
    )
}

export default NewsDetails