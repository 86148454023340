import React from 'react'
import Bredcom from '../../Bredcom/Main'
import First from '../Menservisedeatils/First'

const ServiceDetails = () => {

    return (
        <>
            <Bredcom title={"Home"} subtitle={"SERVICES"} link={"Services Details"}/>
            <First />
        </>
    )
}

export default ServiceDetails