import React from 'react'

function Get() {
    return (
        <>
            <section className="about-two pt-120 pb-120">
                <div className="container">
                    <div className="row row-gutter-y-50">
                        <div className="col-lg-6">
                            <div className="about-two__image">
                                <img src="assets/images/resources/about-2-1.png" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-two__content">
                                <div className="block-title text-left">
                                    <p className="block-title__tagline">About Company</p>
                                    <h2 className="block-title__title">Get to know about our company</h2>

                                </div>
                                <p className="about-two__text">{process.env.REACT_APP_COMPANY_NAME} has been proudly serving our community's lending needs since 2018. Founded by Hayes Barnard, our company is committed to helping our customers achieve their financial goals through responsible lending. Over the past 5 years, we have assisted hundreds of individuals and businesses in obtaining loans to purchase homes, vehicles, cover wedding expenses, invest in property, and expand their companies.
                                With customized options, local expertise and unmatched customer service, {process.env.REACT_APP_COMPANY_NAME} has become the lender-of-choice in our area. We look forward to serving you and helping take your finances to the next level.  </p>
                                <ul className="list-unstyled ml-0 about-two__list">
                                    <li>
                                        <i className="fa fa-arrow-circle-right"></i>
                                        Simple online application.
                                    </li>
                                    <li>
                                        <i className="fa fa-arrow-circle-right"></i>
                                        Fast Funding.
                                    </li>
                                    <li>
                                        <i className="fa fa-arrow-circle-right"></i>
                                        No prepayment,Nop hiding cost.
                                    </li>
                                    <li>
                                        <i className="fa fa-arrow-circle-right"></i>
                                        Bad credit? Don't worry, we believe in second chances.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Get